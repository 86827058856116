import axios from 'axios';
import { endpoints } from './endpoints';
import { IUsersResponse } from '../services/users.service';
import { IRolesResponse } from '../services/roles.service';
import { authProvider } from '../auth/authProvider';
import { FeatureRow } from '../../components/FeatureManagementDrawer/FeatureManagementDrawer';
import { GENERIC_ERROR_MESSAGE } from '../../constants/text';

axios.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
            authProvider.logout();
            return Promise.reject(error);
        }
      if (error?.response?.status === 409) {
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(GENERIC_ERROR_MESSAGE);
    });


export const api = {
    users: {
        getByProductId: async (productId: number): Promise<IUsersResponse> => {
            return await axios.get(endpoints.usersByProductId(productId));
        },
        assignRole: async (userId: string, projectId: number, body: any): Promise<any> => {
            return await axios.put(endpoints.assignUserRole(userId, projectId), body);
        },
        getAllUsers: async (productId: number) => {
            return await request('get', endpoints.getAllUsers(productId));
        },
        getUserFeatures: async (productId: number, userId: number) => {
            return await request('get', endpoints.getUserFeatures(productId, userId));
        },
        updateUserFeatures: async (productId: number, userId: number, features: any[]) => {
            return await request('post', endpoints.getUserFeatures(productId, userId), { features });
        },
    },
    roles: {
        getRolesByProductId: async (productId: number): Promise<IRolesResponse> => {
            return await axios.get(endpoints.getRolesByProductId(productId));
        }
    },
    products: {
        getProducts: async () => {
            return await request('get', endpoints.getProducts());
        }
    },
    companies: {
        getCompaniesPaged: async (productId: number, page: number, pageSize: number) => {
            return await request('post', endpoints.getCompanies(productId), { page, pageSize });
        },
        getCompanyFeatures: async (productId: number, companyId: number) => {
            return await request('get', endpoints.getCompanyFeatures(productId, companyId));
        },
        updateCompanyFeatures: async (productId: number, companyId: number, features: any[]) => {
            return await request('post', endpoints.getCompanyFeatures(productId, companyId), { features });
        },
    },
    features: {
        getFeatures: async (productId: number) => {
            return await request('get', endpoints.getFeatures(productId));
        },
        addFeature: async (productId: number, feature: FeatureRow) => {
            return await request('post', endpoints.addFeature(productId), { ...feature });
        },
        updateFeature: async (productId: number, featureId: number, feature: FeatureRow) => {
            return await request('put', endpoints.updateFeature(productId, featureId), { ...feature });
        },
        deleteFeature: async (productId: number, featureId: number) => {
            return await request('delete', endpoints.deleteFeature(productId, featureId));
        }
    }
}

export type MethodType = 'post' | 'get' | 'put' | 'delete';

const request = async (method: MethodType, url: string, data: any = null) => {
    const result = await authProvider.getIdToken();
    return await axios({
        method,
        url,
        data,
        headers: {
            Authorization: `Bearer ${result?.idToken?.rawIdToken}`,
            resourcename: 'productmanagement'
        }
    });
}