import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { DrawerHeader } from '../MenuDrawer/MenuDrawer';

export const Copyright: FunctionComponent = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center" data-testid="copyright">
            {`Copyright © ${new Date().getFullYear()} Informa PLC.`}
        </Typography>
    );
};

export interface IMainProps {
    children: React.ReactNode;
}

const Main: React.FunctionComponent<IMainProps> = (props) => {
    return (
        <Box component="main" sx={{ flexGrow: 1, padding: '32px 24px' }} data-testid="main-container">
            <DrawerHeader />
            {props.children}
            <Box pt={4}>
                <Copyright />
            </Box>
        </Box>
    )
};

export default Main;