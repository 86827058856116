import React, { FunctionComponent } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './ProgressBar.module.scss';
import { Typography } from '@mui/material';

export interface IProgressBarProps {
    message?: string;
}

export const ProgressBar: FunctionComponent<IProgressBarProps> = (props) => {
    return (
        <div className={styles.initialise} data-testid="progress-bar">
            {props.message &&
                <div className={styles.message} data-testid="progress-bar-message">
                    <Typography variant="h6">
                        {props.message}
                    </Typography>
                </div>
            }
            <LinearProgress color="primary" />
        </div>
    );
}

export default ProgressBar;