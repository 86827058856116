import * as actions from '../actions/actionTypes/auth.actionType';

export interface IAuthState {
    data?: any;
}

const initialState: IAuthState = {
    data: undefined
};

export default function auth(state: IAuthState = initialState, action: actions.AuthAction): IAuthState {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}