import { put, call, takeEvery, all, fork } from 'redux-saga/effects';

import { fetchUsers, assignRole, getAllUsers, getUserFeatures } from '../services/users.service';
import * as actions from '../actions/users.actions';
import * as actionTypes from '../actions/actionTypes/users.actionType';

function* onLoadUsers({ productId }: actionTypes.IGetUsers) {
    try {
        const { data } = yield call(fetchUsers, productId);
        yield put(actions.getUsersSuccess(data));
    } catch (error) {
        yield put(actions.getUsersError(error.response.data.error));
    }
}

function* onLoadAllUsers({ productId }: actionTypes.IGetAllUsers) {
    try {
        const { data } = yield call(getAllUsers, productId);
        yield put(actions.getUsersSuccess(data));
    } catch (error) {
        yield put(actions.getUsersError(error.response.data.error));
    }
}

function* onLoadUserFeatures({ productId, userId }: actionTypes.IGetUserFeatures) {
    try {
        const { data } = yield call(getUserFeatures, productId, userId);
        yield put(actions.getUserFeaturesSuccess(data));
    } catch (error) {
        yield put(actions.getUserFeaturesError(error?.response?.data?.error || error));
    }
}

function* onAssignRole({ userId, productId, role }: actionTypes.IAssignRole) {
    try {
        const { data } = yield call(assignRole, userId, productId, role);
        yield put(actions.assignRoleSuccess(data));
    } catch (error) {
        yield put(actions.getUsersError(error.response.data.error));
    }
}

function* watchOnLoadUsers() {
    yield takeEvery(actionTypes.GET_USERS, onLoadUsers);
}

function* watchOnLoadAllUsers() {
    yield takeEvery(actionTypes.GET_ALL_USERS, onLoadAllUsers);
}

function* watchOnAssignRole() {
    yield takeEvery(actionTypes.ASSIGN_ROLE, onAssignRole);
}

function* watchOnLoadUserFeatures() {
    yield takeEvery(actionTypes.GET_USER_FEATURES, onLoadUserFeatures);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnLoadUsers),
        fork(watchOnLoadAllUsers),
        fork(watchOnAssignRole),
        fork(watchOnLoadUserFeatures)
    ]);
}
