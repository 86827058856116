export const LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';

export interface IActiveDirectoryData {
    account: any;
}

export interface ILoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: IActiveDirectoryData;
}

export type AuthAction =
    | ILoginSuccess;