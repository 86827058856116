import React, { FunctionComponent } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Box } from '@mui/material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { authProvider } from '../../core/auth/authProvider';
import { getUserInitials } from '../../core/selectors/auth.selectors';
import { getSelectedProduct } from '../../core/selectors/product.selectors';
import logo from '../../images/logos/PharmaIntelligence.png';
import { AppBar } from '../AppBar/AppBar';
import Main from '../Main/Main';
import { MenuDrawer, DrawerHeader } from '../MenuDrawer/MenuDrawer';
import { MenuItems } from '../MenuItems/MenuItems';
import { toolBarStyles } from './BaseLayoutStyles';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';

export interface IBaseLayoutProps {
    children: React.ReactNode;
}

const BaseLayout: FunctionComponent<IBaseLayoutProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const selectedProduct = useSelector(getSelectedProduct);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const menuDrawerDesc = open ? 'close' : 'open';

    const userInitials = useSelector(getUserInitials);

    return (
        <div className="flex">
            <CssBaseline />
            <AppBar position="fixed"
                open={open}
                color="secondary"
                data-testid="appbar">
                <Toolbar sx={toolBarStyles.root}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label={`${menuDrawerDesc} menu`}
                        onClick={handleDrawerOpen}
                        data-testid="open-menu-button"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <a href="https://pharmaintelligence.informa.com/" target="_blank" rel="noopener noreferrer" className="flex">
                        <img src={logo} alt="Pharma Intelligence Logo" data-testid="pharma-intelligence-logo" />
                    </a>
                    <Box sx={toolBarStyles.middleSection}>
                        <Typography component="span" variant="body1" color="inherit" noWrap>
                            Selected Product: <strong>{selectedProduct ? selectedProduct?.name : 'None'}</strong>
                        </Typography>
                        <Link to="/product-selection"><Typography color="primary"><strong> (change)</strong></Typography></Link>
                    </Box>
                    <Typography component="h1" variant="h6" color="inherit" noWrap>
                        Pharma Product management tool
                    </Typography>
                </Toolbar>
            </AppBar>

            <MenuDrawer
                variant="permanent"
                open={open}
                data-testid={`${menuDrawerDesc}-slide-menu-panel`}
            >
                <DrawerHeader>
                    <div className='nameWrapper'>
                        <div className='userIcon'>{userInitials}</div>
                        <Button variant="contained" color="inherit" onClick={() => authProvider.logout()}>
                            Log out
                        </Button>
                    </div>
                    <IconButton onClick={handleDrawerClose} data-testid="close-menu-button">
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <MenuItems />
            </MenuDrawer>

            <Main>{props.children}</Main>
            <SnackBarNotification />
        </div>
    );
}

export default BaseLayout;