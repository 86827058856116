import React from 'react';
import BaseLayout from './components/BaseLayout/BaseLayout';
import App from './App';

class AppContainer extends React.PureComponent {
    public render() {
        return (
            <BaseLayout>
                <App />
            </BaseLayout>
        );
    }
}

export default AppContainer