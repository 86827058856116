export function environment() {
    if (!window || !window.location) {
        return 'dev';
    }
    const hostname = window.location.hostname;

    if (!hostname || hostname === 'localhost' || hostname.indexOf('.dev.') > 0) {
        return 'dev';
    }

    if (hostname.indexOf('.test.') > 0) {
        return 'test';
    }

    return 'prod'
}

export function apiToUse() {
    const apiToUse = process.env.REACT_APP_API;

    if (apiToUse === 'prod' || apiToUse === 'test' || apiToUse === 'dev' || apiToUse === 'local')
        return apiToUse

    return environment()
}

export const getDefaultEndpoint = (): string => {
    const apiEnv = apiToUse();

    switch (apiEnv) {
        case 'local':
            return 'http://localhost:5005/dev/v1/api'
        
        case 'prod':
            return 'https://pharma-user-api-service.pharma.intelligence.informa.com/v1/api';

        default:
            return `https://pharma-user-api-service.${apiEnv}.p360.pharma.informa-labs.com/v1/api`;
    }

};

export const endpoints = {
    usersByProductId(productId: number) {
        return `${getDefaultEndpoint()}/users/${productId}`;
    },
    getRolesByProductId(productId: number) {
        return `${getDefaultEndpoint()}/roles/${productId}`;
    },
    assignUserRole(userId: string, productId: number) {
        return `${getDefaultEndpoint()}/user/${productId}/${userId}`;
    },
    getProducts() {
        return `${getDefaultEndpoint()}/products`;
    },
    getCompanies(productId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/companies`;
    },
    getCompanyFeatures(productId: number, companyId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/company/${companyId}/features`;
    },
    getFeatures(productId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/features`;
    },
    addFeature(productId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/features`;
    },
    updateFeature(productId: number, featureId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/features/${featureId}`;
    },
    deleteFeature(productId: number, featureId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/features/${featureId}`;
    },
    getAllUsers(productId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/users`;
    },
    getUserFeatures(productId: number,  userId: number) {
        return `${getDefaultEndpoint()}/project/${productId}/user/${userId}/features`;
    },
};