import { AppState } from '../reducers/root.reducer';
import { createSelector } from 'reselect';

const emptyUser = {};

const getFirstLetter = (word: string): string => word && word.length > 0 ? word.substring(0, 1).toUpperCase() : '';

export const getUserInfo = (state: AppState): any => state.authData?.data || emptyUser;

export const getUserInitials = createSelector(getUserInfo,
    (data) => {
        const userData = data?.account?.idToken;
        if (userData) {
            const name = [getFirstLetter(userData.given_name), getFirstLetter(userData.family_name)]
                .filter(Boolean).join(' ');
            return name;
        }

        return '';
});

