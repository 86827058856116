/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import ProgressBar from './components/ProgressBar/ProgressBar';
import { ThemeProvider } from '@mui/material';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import { themeLight } from './components/MaterialUITheme';
import AppContainer from './AppContainer';
import configureStore from './core/store/configure-store';
import { authProvider } from './core/auth/authProvider';
import { Forbidden } from './components/AuthComponents/Forbidden';

import './index.scss';
import { CustomRouter } from './CustomRouter';

const history = createBrowserHistory();
const store = configureStore();


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeLight}>
      <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
        {
          ({
            authenticationState, // the current authentication state
          }: any) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return (
                  <Provider store={store}>
                    <CustomRouter history={history}>
                      <AppContainer />
                    </CustomRouter>
                  </Provider>
                );
              case AuthenticationState.Unauthenticated:
                return <Forbidden />;
              case AuthenticationState.InProgress:
                return <ProgressBar message="Redirecting you to registration/login…" />
            }
          }}
      </AzureAD>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
