import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import CompanyFeatureManagement from './components/CompanyFeatureManagement/CompanyFeatureManagement';
import FeatureManagement from './components/FeatureManagement/FeatureManagement';
import ProductSelectionRoute from './components/ProductSelectionRoute/ProductSelectionRoute';
import UserFeatureManagement from './components/UserFeatureManagement/UserFeatureManagement';

const Home = lazy(() => import('./components/Dashboard/Dashboard'));
const ProductSelection = lazy(() => import('./components/ProductSelection/ProductSelection'));

const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/company-feature-management" element={
          <ProductSelectionRoute><CompanyFeatureManagement /></ProductSelectionRoute>
        } />
        <Route path="/product-selection" element={<ProductSelection />} />
        <Route path="/feature-management" element={
          <ProductSelectionRoute><FeatureManagement /></ProductSelectionRoute>
        } />
         <Route path="/user-feature-management" element={
          <ProductSelectionRoute><UserFeatureManagement /></ProductSelectionRoute>
        } />
      </Routes>
    </Suspense>
  );
};

export default App;

